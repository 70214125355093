// new methods should be made in /algolia-cotado/algoliaClient.ts

import algoliasearch from 'algoliasearch';
import qs from 'qs';
import {
  BookRecipeParams,
  SearchConfig,
  SearchState,
  AlgBrowseParams,
  ParsedQuery,
} from 'types/algolia';
import facets from './facets';

const APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? '';
const API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN ?? '';

const oldAlgoliaEnv =
  process.env.NEXT_PUBLIC_ALGOLIA_ENVIRONMENT || 'production';
const algoliaEnv = `cortado_${
  process.env.NEXT_PUBLIC_ALGOLIA_ENVIRONMENT?.replace('cortado_', '') ||
  'staging'
}`;

const indexPrefix = 'everest_search';
const client = algoliasearch(APP_ID, API_KEY);

// keeping line to pre-cortado algolia because new indices are not yet up on algolia
const getIndexNameOld = (context = '') => {
  const indName = `${indexPrefix}${
    context ? `_${context}` : ''
  }_${oldAlgoliaEnv}`;
  return indName;
};
const getIndexName = (context = '') =>
  `${indexPrefix}${context ? `_${context}` : ''}_${algoliaEnv}`;

const index = client.initIndex(getIndexName());
const oldIndex = client.initIndex(getIndexNameOld());
const latestIndexName = getIndexName('published_date_desc');
const latestIndex = client.initIndex(latestIndexName);

// filter for app only search
export function filters(filterString: string) {
  return `NOT f_hideFrom:web AND ${filterString}`;
}

export const defaultAttributesToRetrieve: string[] = [
  'objectID',
  'title',
  'search_cloudinary_id',
  'search_avg_rating',
  'search_user_ratings_count',
  'search_comment_count',
  'search_document_klass',
  'search_document_klass_formatted',
  'search_stickers',
  'search_site_list',
  'search_atk_buy_now_link',
  'search_url',
  'search_cookbook_collection',
  'search_price',
  'search_sale_price',
  'isNew',
  'authorsAndImageInitials',
  'label',
  'hasVideo',
  'f_cookTime',
];

export const getBookRecipes = async ({
  slug,
  documentCount,
  recipeSlug,
}: BookRecipeParams) => {
  const hitsPerPage = 6;
  const pageCount = Math.floor(documentCount / hitsPerPage);
  const page = Math.min(Math.floor(Math.random() * pageCount) + 1, 5);
  const config: SearchConfig = {
    attributesToHighlight: null,
    attributesToRetrieve: defaultAttributesToRetrieve,
    enableRules: false,
    facetFilters: [
      ['search_document_klass:recipe'],
      [`search_browse_slugs:${slug}`],
    ],
    hitsPerPage,
    page,
  };
  const response = await oldIndex.search(config);
  const documents = response.hits.filter(
    (hits: any) => hits.search_url !== `/recipes/${recipeSlug}`,
  );
  return { slug, documents };
};

const bookFacetFilters: string[][] = [['search_document_klass:cookbook']];
const titleBlacklist = [
  'best',
  'better',
  'classic',
  'easy',
  'foolproof',
  'quick',
  'simple',
  'ultimate',
];
const blacklistRegex = new RegExp(titleBlacklist.join('|'), 'g');
const fixCourseQuery = (query: string) =>
  query.toLowerCase().replace(blacklistRegex, '').trim();

const reviewableFacetFilters = [
  [
    'search_document_klass:recipe',
    'search_document_klass:equipment_review',
    'search_document_klass:taste_test',
    'search_document_klass:how_to',
    'search_document_klass:article',
  ],
];

const buildQuery = (
  query: string,
  facetFilters: string[][],
  hitsPerPage = 1,
) => ({
  indexName: getIndexName(),
  params: {
    attributesToHighlight: null,
    enableRules: false,
    facetFilters,
    hitsPerPage,
    query: query.toString(),
  },
});

const getBookTypeQuery = (
  bookQueries: (type: string) => Record<string, string>,
  type: string,
): string => {
  const bookQueryData = bookQueries(type);
  return bookQueryData[type] || bookQueryData.default;
};

const getEpisodeBook = async (
  { bookQueries }: { bookQueries: (type: string) => Record<string, string> },
  type: string,
): Promise<any> => {
  const bookQuery = getBookTypeQuery(bookQueries, type);
  const queries = [buildQuery(bookQuery, bookFacetFilters)];
  try {
    const response = await client.search(queries);
    const hits = response?.results?.[0]?.hits ?? null;
    return hits;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching episode book:', error);
    return null;
  }
};

export const getLatestReviews = async () => {
  const hitsPerPage = 9;
  const config: SearchConfig = {
    attributesToHighlight: null,
    attributesToRetrieve: defaultAttributesToRetrieve,
    enableRules: false,
    facetFilters: [
      [
        'search_document_klass:equipment_review',
        'search_document_klass:taste_test',
      ],
      'search_site_list:atk',
    ],
    hitsPerPage,
  };
  const { hits } = await latestIndex.search(config);
  return { hits };
};

export const getFeaturedRecipe = async (siteKey: string) => {
  const config: SearchConfig = {
    attributesToHighlight: null,
    facetFilters: [['search_document_klass:recipe']],
    hitsPerPage: 1,
  };

  const seasonIndexName = getIndexName(
    `${siteKey}_${siteKey === 'cio' ? 'magazine' : 'season'}_desc`,
  );
  const seasonDescIndex = client.initIndex(seasonIndexName);

  const response = await seasonDescIndex.search(config);
  return response.hits[0] || null;
};

export const getInstantSearchDocuments = (searchState: SearchState) => {
  const {
    configure: { enableRules = false, filters = '', hitsPerPage = 12 },
    menu = {},
    refinementList = {},
  } = searchState;

  let config = {};
  try {
    let facetFilters = filters?.split(' OR ') || [];
    if (refinementList) {
      const refinementFilters = Object.entries(refinementList).map(
        ([key, values]) => values.map((value) => `${key}:${value}`),
      );
      facetFilters = [...facetFilters, ...refinementFilters.flat()];
    }
    if (menu) {
      facetFilters = [
        ...facetFilters,
        ...Object.entries(menu).map(([key, value]) => `${key}:${value}`),
      ];
    }

    config = {
      attributesToHighlight: null,
      attributesToRetrieve: defaultAttributesToRetrieve,
      enableRules,
      facetFilters,
      hitsPerPage,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error parsing facetFilters and refinementFilters', err);
  }
  return latestIndex.search(config);
};

let lastQuery = '';
let lastResult: { query: string; course: any } | null = null;

export const getSchoolCourse = async (
  query: string,
  isOverride = false,
): Promise<{ query: string; course: any }> => {
  if (query === lastQuery) {
    return lastResult!;
  }

  const cleanQuery = isOverride ? query : fixCourseQuery(query);

  const config: SearchConfig = {
    attributesToHighlight: null,
    query: cleanQuery,
    enableRules: false,
    facets: 'search_document_klass',
    facetFilters: [['search_document_klass:course']],
    attributesToRetrieve: [
      'title',
      'description',
      'search_url',
      'search_cloudinary_id',
      'search_page_content',
      'search_related_data',
    ],
    hitsPerPage: 1,
    removeStopWords: !isOverride,
  };

  try {
    const response = await index.search(config);
    const course = response.hits[0];

    lastQuery = query;
    lastResult = { query, course };

    return { query, course };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching school course:', error);
    return { query, course: null };
  }
};

export const getLatestRelatedCarousel = async (
  facet: string,
  typeList: string,
): Promise<{ hits: any[] }> => {
  const formattedFacet =
    facet === 'review_type_list' ? 'equipment_review' : 'taste_test';
  const searchType =
    formattedFacet === 'equipment_review'
      ? 'search_review_type_list'
      : 'search_test_type_list';

  const config: SearchConfig = {
    attributesToHighlight: null,
    attributesToRetrieve: defaultAttributesToRetrieve,
    enableRules: false,
    facetFilters: [
      [`search_document_klass:${formattedFacet}`],
      [`${searchType}:${typeList}`],
      ['search_site_list:atk'],
    ],
    hitsPerPage: 9,
  };

  try {
    const { hits } = await latestIndex.search(config);
    return { hits };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching latest related carousel:', error);
    return { hits: [] };
  }
};

const getRecipesBrowseState = ({
  asPath,
  docType,
  facetType,
  filters,
  indexType,
  pageSize,
  translations,
}: AlgBrowseParams) => {
  /* eslint-disable camelcase */
  const {
    browse: { document_klass_singular, recipes },
  } = translations;

  const queryString = asPath.substring(asPath.indexOf('?') + 1);

  // Parse the query and cast to the custom type
  const parsedQuery = qs.parse(queryString) as ParsedQuery;

  // Destructure refinementList and remaining parameters
  const { refinementList: parsedRefList = {}, ...parsedPath } = parsedQuery;
  /**
   * Some old urls and facet slugs like recipes/browse/salads are returning [0][0] values
   *  for keys like search_browse_slugs. They loop infinately with our to be refactored implementation.
   * This handles those old urls or redirects which will no longer be detected by fault/87606472
   * e.g. https://www.americastestkitchen.com/recipes/browse?refinementList%5Bsearch_browse_slugs%5D%5B0%5D%5B0%5D=grilling&refinementList%5Bsearch_document_klass%5D%5B0%5D=recipe
   * from https://www.americastestkitchen.com/books/healthy-and-delicious-instant-pot
   */
  Object.entries(parsedRefList).forEach(([key, value]) => {
    if (
      Array.isArray(value) &&
      Array.isArray(value[0]) &&
      value[0].length === 1
    ) {
      parsedRefList[key] = value[0];
    }
  });
  // TODO: redefine refinementList when removing qs library

  const refinementList = { ...parsedRefList };
  if (facetType && recipes[facetType]) {
    refinementList.search_browse_slugs = [facetType];
  }

  if (docType && document_klass_singular[docType]) {
    refinementList.search_document_klass = [document_klass_singular[docType]];
  }
  /* eslint-enable camelcase */

  return {
    ...parsedPath,
    configure: {
      analytics: true,
      enableRules: false,
      filters,
      hitsPerPage: pageSize,
    },
    basePath: indexType,
    refinementList,
  };
};

const getReviewableRelateds = (
  {
    bookQueries,
    subtitle: query,
    documentType,
    documentId,
  }: {
    bookQueries: (type: string) => Record<string, string>;
    subtitle: string;
    documentType: string;
    documentId: string;
  },
  type: string,
): Promise<any[]> => {
  const bookQuery = getBookTypeQuery(bookQueries, type);
  const queries = [
    buildQuery(query, reviewableFacetFilters, 5),
    buildQuery(bookQuery, bookFacetFilters),
  ];

  return new Promise((resolve, reject) => {
    client.search(queries, (err: any, response: any) => {
      if (err) {
        reject(new Error(`Error during search: ${err.message}`));
        return;
      }

      const results = response?.results || [];
      let relateds = results[0]?.hits || [];

      if (relateds.length > 0) {
        const currentId = `${documentType}_${documentId}`;
        relateds = relateds.filter(
          (hits: { objectID: string }) => hits.objectID !== currentId,
        );
      }

      const bookResult = results[1]?.hits?.[0];
      if (bookResult) {
        relateds.splice(2, 1, bookResult);
      }

      resolve(relateds);
    });
  });
};

const getReviewsBrowseState = ({
  docType,
  facetType,
  filters,
  indexType,
  pageSize,
  translations,
}: AlgBrowseParams) => {
  /* eslint-disable camelcase */
  const {
    browse: { document_klass_singular, equipment_reviews, taste_tests },
  } = translations;

  const refinementList: Record<string, string[]> = {};
  const menu: Record<string, any> = {};
  const isTrendingOrCurrent =
    indexType === 'trending' || indexType === 'current_season';
  // This first condition covers legacy URLs. they would follow the pattern below:
  // /:docType(ER/TT)/browse/:facetType
  if (docType && document_klass_singular[docType] && !isTrendingOrCurrent) {
    refinementList.search_document_klass = [document_klass_singular[docType]];

    if (
      docType === 'equipment_reviews' &&
      facetType &&
      equipment_reviews[facetType]
    ) {
      menu.search_review_type_list = equipment_reviews[facetType];
    }

    if (docType === 'taste_tests' && facetType && taste_tests[facetType]) {
      menu.search_test_type_list = taste_tests[facetType];
    }
    // This case covers new URLs
    // /reviews/:indexType(browse|buying_guides|equipment_reviews|taste_tests|trending)
  } else if (indexType) {
    const indexDocTypes = ['buying_guides', 'equipment_reviews', 'taste_tests'];
    if (indexDocTypes.includes(indexType)) {
      refinementList.search_document_klass = [
        document_klass_singular[indexType],
      ];
    } else if (indexType === 'trending') {
      refinementList.search_browse_slugs = ['trending'];
      refinementList.search_document_klass = [document_klass_singular[docType]];
    } else if (indexType === 'current_season') {
      refinementList.search_review_in_current_season = ['true'];
      if (docType)
        refinementList.search_document_klass = [
          document_klass_singular[docType],
        ];
    }
  }
  /* eslint-enable camelcase */

  return {
    configure: {
      analytics: true,
      enableRules: false,
      filters,
      hitsPerPage: pageSize,
    },
    basePath: docType || indexType,
    refinementList,
    menu,
  };
};

const magazineIndexes: { [key: string]: string } = {
  atk: '',
  cco: 'cco_magazine_desc_',
  cio: 'cio_magazine_desc_',
};

const getLatestMagazine = (siteKey: string) => {
  const magIdx = magazineIndexes[siteKey];
  let result = Promise.resolve({});
  if (typeof magIdx !== 'undefined') {
    const magazineIndex = client.initIndex(
      `everest_search_${magIdx}${algoliaEnv}`,
    );
    const response = magazineIndex.search({
      attributesToHighlight: null,
      enableRules: false,
      facetFilters: ['search_document_klass:magazine'],
      hitsPerPage: 1,
    });
    result = Promise.resolve(response).then((r) => r.hits[0]);
  }
  return result;
};

const getLatestMagazineDocuments = (siteKey: string, documentTypes = []) => {
  const config = {
    attributesToHighlight: null,
    facetFilters: [
      documentTypes.map(
        (documentType) => `search_document_klass:${documentType}`,
      ),
    ],
    hitsPerPage: 12,
  };

  const magazineIndexName = getIndexName(`${siteKey}_magazine_desc`);
  const magazineIndex = client.initIndex(magazineIndexName);

  return magazineIndex.search(config);
};

//TODO: redefine facets
const getValidSubFacets = (
  parentFacetName: string,
  refined: string,
): string[] => {
  if (parentFacetName in facets) {
    const parentFacet = facets[parentFacetName as keyof typeof facets];

    if (refined in parentFacet) {
      return parentFacet[refined as keyof typeof parentFacet] as string[];
    }
  }
  return [];
};

const getRefinedFacet = (
  refinementList: { [x: string]: any },
  attribute: string | number,
) => (refinementList && refinementList[attribute]) || null;

const isFacetRefined = (
  refinementList: { [x: string]: any },
  attribute: string | number,
  value: any,
) => {
  let isRefined = false;
  if (refinementList) {
    const refinedAttribute = getRefinedFacet(refinementList, attribute);
    if (refinedAttribute) {
      if (Array.isArray(refinedAttribute)) {
        isRefined = refinedAttribute.includes(value);
      } else {
        isRefined = refinedAttribute === value;
      }
    }
  }
  return isRefined;
};
export const getAlgoliaDocuments = async (
  facetFilters: string[][],
  indexSlug = '',
  hitsPerPage = 12,
) => {
  const config: SearchConfig = {
    attributesToHighlight: null,
    facetFilters,
    hitsPerPage,
  };

  const algoliaIndexName = getIndexName(indexSlug);
  const algoliaIndex = client.initIndex(algoliaIndexName);
  return algoliaIndex.search(config);
};
export const algoliaClient = client;

const algolia = {
  getIndexNameOld,
  getAlgoliaDocuments,
  getInstantSearchDocuments,
  getRecipesBrowseState,
  getReviewsBrowseState,
  getFeaturedRecipe,
  getIndexName,
  getBookRecipes,
  getEpisodeBook,
  getLatestMagazine,
  getLatestMagazineDocuments,
  getLatestReviews,
  getLatestRelatedCarousel,
  getReviewableRelateds,
  getSchoolCourse,
  getValidSubFacets,
  getRefinedFacet,
  isFacetRefined,
};

export default algolia;
