import { useRouter } from 'next/router';
import GlobalHeader from 'components/CVMigratedNav/GlobalHeader/GlobalHeader';
import useGlobalHeaderConfig, { BrandKey } from './useGlobalHeaderConfig';

import UIFooter from '../ui-layout/UIFooter';
import UIHeader from '../ui-layout/UIHeader';

type GlobalLayoutProps = {
  brandKey: BrandKey;
  children: React.ReactNode;
  isPlay: boolean;
  strapiHeaderData: any;
};

const GlobalLayout = ({
  brandKey,
  children,
  isPlay,
  strapiHeaderData,
}: GlobalLayoutProps) => {
  const { asPath, pathname } = useRouter();
  const config = useGlobalHeaderConfig(
    isPlay ? 'play' : brandKey,
    pathname,
    asPath,
  );

  const ignoredPaths = [
    '/access',
    '/magazines',
    '/automatic-renewal-program',
    '/print',
    '/order',
  ];

  let includeGlobalComponents = true;

  if (ignoredPaths.some((path) => pathname.includes(path))) {
    if (!pathname.includes('/order/confirmation')) {
      includeGlobalComponents = false;
    }
  }

  return (
    <>
      {isPlay && includeGlobalComponents && <GlobalHeader {...config} />}
      {!isPlay && includeGlobalComponents && (
        <UIHeader strapiData={strapiHeaderData} />
      )}
      {children}
      {includeGlobalComponents && (
        <div id="atk-global-footer">
          <UIFooter />
        </div>
      )}
    </>
  );
};

export default GlobalLayout;
