import analytics from 'lib/analytics';
import useAppContext from 'lib/hooks/useAppContext';
import { useCallback, useMemo, useRef, useState } from 'react';
import { UserSegment } from 'lib/hooks/useUserState';
import parseUserClient from '../../components/integrations/Mixpanel/parseUserClient';
import atk from 'lib/api/atk/atk';
import AnalyticsService from 'lib/analytics/mixpanel/mixpanel-browser';
import events from 'lib/analytics/mixpanel/events';
import {
  DisplayAdArgument,
  EmailCaptureProperties,
  useTrackDisplayAdAcceptedCallback,
  useTrackDisplayAdPresentedEffect,
  useTrackEmailCaptureCallback,
} from './mixpanel/use-ad-tracking';
import { getEndpoint } from 'lib/api/atk/endpoints';
import honeybadger from 'lib/errors/honeybadger';
import utils from 'lib/utils';
const categories = [
  'Homepage Notes from the Test Kitchen',
  'Well-Equipped Cook',
  'Notes from the Test Kitchen',
  'Dinner Tonight',
  'Cooking for One',
  'Five-Ingredient Dinners',
  "Cook's Insider",
  'Mediterranean',
  'Plant Based',
  'Thanksgiving',
  'Recipe Tester/Survey Sign Up',
  "America's Test Kitchen Watch and Cook",
  "Cook's Country Watch and Cook",
  'Baking',
  'Shortcuts to Dinner',
  'Eggs Pop-up',
] as const;

/** Barista Categories and mixpanel value for 'type' in Newsletter Preferences. */
export type Category = typeof categories[number];

/** Component properties passed to ReviewsInlineNewsletter Component */
type ViewData = {
  title: string;
  description: string;
  successText: string;
  buttonText?: string;
};
export type HomepageViewData = Record<
  'frequency' | 'days' | 'title' | 'description' | 'successText',
  string
>;

/** Values originally passed to /newsletter/signup endpoint */
type SignupData = {
  newsletter_id: string;
  triggered_send?: string;
  email: string | null;
  sfmc_params: object;
};

/** Values originally passed to /newsletter/journey_event endpoint */
type JourneyEventData = {
  event_definition_key: string;
  de_name?: string;
  newsletter_id: string | null;
  email: string | null;
  sfmc_params: object;
};

/** Static data for article newsletters rendering */
type NewsletterData = {
  view: ViewData | HomepageViewData;
  meta: SignupData | JourneyEventData;
};

/**
 * Static data for Barista rendered article newsletters
 * @type {Record<Category, NewsletterData>}
 */
const newsletters: Record<Category, NewsletterData> = {
  'Homepage Notes from the Test Kitchen': {
    view: {
      frequency: 'Weekly',
      days: 'Fridays',
      title: 'Notes From the Test Kitchen',
      description:
        'The latest from the test kitchen—our best seasonal recipes, equipment reviews, cooking advice, and behind-the-scenes spotlights of our cast, crew, and staff.',
      successText:
        '<strong>Thank you!</strong> Get ready for Notes from the Test Kitchen Newsletter in your inbox.',
    },
    meta: {
      newsletter_id: '3',
      email: null,
      sfmc_params: {},
    },
  },
  'Well-Equipped Cook': {
    view: {
      title: 'Sign up for the Well-Equipped Cook newsletter',
      description:
        "Shop smarter with our ATK Reviews team's expert guides and recommendations.",
      successText:
        'Thank you! Get ready for Well-Equipped Cook in your inbox on Wednesdays!',
    },
    meta: {
      newsletter_id: '6',
      email: null,
      sfmc_params: {},
    },
  },
  'Notes from the Test Kitchen': {
    view: {
      title: 'Sign up for the <em>Notes from the Test Kitchen</em> newsletter',
      description:
        'Our favorite tips and recipes, enjoyed by 2 million+ subscribers!',
      successText:
        'Thank you. Get ready for Notes from the Test Kitchen Newsletter in your inbox.',
    },
    meta: {
      newsletter_id: '3',
      email: null,
      sfmc_params: {},
    },
  },
  'Dinner Tonight': {
    view: {
      title: 'Sign up for the <em>Dinner Tonight</em> newsletter!',
      description:
        'Weeknight cooking inspiration, curated and written by longtime ATK author and editor (and avid home cook) Jack Bishop.',
      successText:
        'Thank you. Get ready for weeknight meals from the Dinner Tonight cook in your inbox.',
    },
    meta: {
      newsletter_id: '16',
      email: null,
      sfmc_params: {},
    },
  },
  'Cooking for One': {
    view: {
      title: 'Sign up for the <em>Cooking for One</em> newsletter',
      description:
        'A one-week meal plan featuring recipes from our best-selling book.',
      successText:
        'All signed up! Keep an eye on your inbox, and we wish you a tasty dinner for one.',
    },
    meta: {
      event_definition_key: 'APIEvent-f07378a3-b425-4f42-f5c3-a9261b086de3',
      newsletter_id: null,
      email: null,
      sfmc_params: {},
    },
  },
  'Five-Ingredient Dinners': {
    view: {
      title: 'Sign up for the <em>Five-Ingredient Dinners</em> newsletter',
      description:
        'Five recipes and five kitchen hacks to make mealtime manageable.',
      successText: 'Thank you. All signed up!',
    },
    meta: {
      event_definition_key: 'APIEvent-91b603ee-f0a4-e6de-afce-880f05420fb1',
      newsletter_id: null,
      email: null,
      sfmc_params: {},
    },
  },
  "Cook's Insider": {
    view: {
      title: "Sign up for the Cook's Insider newsletter",
      description:
        "The latest recipes, tips, and tricks, plus behind-the-scenes stories from the <em>Cook's Illustrated</em> team.",
      successText: 'Thank you. All signed up!',
    },
    meta: {
      newsletter_id: '7',
      email: null,
      sfmc_params: {},
    },
  },
  Mediterranean: {
    view: {
      title: 'Sign up for the <em>Mediterranean Way</em> newsletter',
      description:
        'Bring the vibrant, healthful recipes of the Mediterranean to your kitchen.',
      successText:
        'Thank you! Get ready for Mediterranean recipes and tips in your inbox shortly.',
    },
    meta: {
      de_name: 'The_Mediterranean_Way_Entry',
      event_definition_key: 'APIEvent-98014a15-9ff3-eb9d-a14c-51f9bf3ece87',
      newsletter_id: null,
      email: null,
      sfmc_params: {},
    },
  },
  Thanksgiving: {
    view: {
      title: 'Sign up for <em>The Thanksgiving Success Series</em> ',
      description:
        'Be Thanksgiving-ready with the help of the Test Kitchen experts.',
      successText:
        'Thank you! Stay tuned for Thanksgiving recipes and hosting tips in your inbox starting in November.',
    },
    meta: {
      de_name: 'TGX_Pop_Up_Entry',
      event_definition_key: 'APIEvent-89968574-9a6e-d72a-e434-a4e3e878409e',
      newsletter_id: null,
      email: null,
      sfmc_params: {},
    },
  },
  'Plant Based': {
    view: {
      title:
        'Sign up for the <em>Essentials of Plant-Based Cooking</em> newsletter.',
      description:
        'Make plants the star of the show with delicious recipes that fit your lifestyle.',
      successText:
        'Thank you! Get ready for plant-based recipes and tips in your inbox shortly.',
    },
    meta: {
      de_name: 'Plant_Based_Pop_Up_Journey_Entry',
      event_definition_key: 'APIEvent-31388e4b-9b80-2005-2515-31f10339c6bb',
      newsletter_id: null,
      email: null,
      sfmc_params: {},
    },
  },
  'Recipe Tester/Survey Sign Up': {
    view: {
      title: 'Sign up to be an ATK Recipe Tester',
      description:
        'Join our community of home cooks helping us create foolproof recipes.',
      successText: "Thank you - you'll receive an email confirmation shortly!",
    },
    meta: {
      newsletter_id: '14',
      email: null,
      sfmc_params: {},
    },
  },
  "America's Test Kitchen Watch and Cook": {
    view: {
      title:
        "Sign up for the <em>America's Test Kitchen Watch and Cook</em> newsletter",
      description:
        'Latest recipes, episodes, and behind-the-scenes stories from the ATK team.',
      successText: "Thank you! You're all signed up.",
    },
    meta: {
      newsletter_id: '9',
      email: null,
      sfmc_params: {},
    },
  },
  "Cook's Country Watch and Cook": {
    view: {
      title:
        "Sign up for the <em>Cook's Country Watch and Cook</em> newsletter",
      description:
        'Latest recipes, episodes, and behind-the-scenes stories from the CC team.',
      successText: "Thank you! You're all signed up.",
    },
    meta: {
      newsletter_id: '10',
      email: null,
      sfmc_params: {},
    },
  },
  Baking: {
    view: {
      title: 'Sign up for the newsletter <em>How to Bake</em>',
      description:
        'Go from beginner to confident baker with how-tos and free recipes.',
      successText:
        'Thank you! Get ready for baking recipes and expert tips in your inbox shortly.',
    },
    meta: {
      de_name: 'Baking_Pop_Up_Journey_Entry',
      event_definition_key: 'APIEvent-7311b3e1-73f7-21aa-43b6-33eb3b0b0d76',
      newsletter_id: null,
      email: null,
      sfmc_params: {},
    },
  },
  'Eggs Pop-up': {
    view: {
      title: 'Master eggs in two weeks',
      description: 'Crack the code on 6 novel techniques',
      successText:
        'Thank you! Get ready for <em>You Can Make the Perfect Eggs</em> in your inbox shortly.',
    },
    meta: {
      de_name: 'Eggs_Newsletter_Journey_Entry',
      event_definition_key: 'APIEvent-83983eb4-c4a0-8366-bd48-2cceb293d375',
      newsletter_id: null,
      email: null,
      sfmc_params: {},
    },
  },
  'Shortcuts to Dinner': {
    view: {
      title: 'Get our <em>Shortcuts to Dinner</em> newsletter',
      description: 'Experience the magic of genius recipes & ingredient hacks.',
      successText:
        'Thanks for signing up! Your first issue of Shortcuts to Dinner is on its way to your inbox now.',
      buttonText: 'YES, COUNT ME IN',
    },
    meta: {
      de_name: 'Jack_API_Start',
      event_definition_key: 'APIEvent-74066447-d82f-56f3-b827-540199d280c6',
      newsletter_id: null,
      email: null,
      sfmc_params: {},
    },
  },
};

/**
 * Hits CIO enpoints for app/controllers/newsletter_controller.rb.
 * @param {NewsletterData['meta']} meta
 * @returns {(email: string) => void}
 */

const contentTypeToDocumentType: Record<string, string> = {
  TasteTest: 'taste_test',
  EquipmentReview: 'equipment_review',
  article: 'article',
  barista_article: 'article',
  guide: 'guide',
  recipe: 'recipe',
};

export async function updateNewsletterPreferences(
  url: string,
  newsletterId: number,
  userEmail: string,
  method: string,
  userSegment: string,
  currentUrl: string,
  emailCaptureType: string,
  id?: string,
  title?: string,
  type?: string,
): Promise<any> {
  const selectedNewsletters = [newsletterId];
  const fetchData = {
    method: method,
    body: JSON.stringify({
      newsletters: selectedNewsletters,
      email: userEmail,
      sfmc_params: {
        email_capture_type: emailCaptureType,
        user_segment: userSegment,
        doc_title: title,
        doc_type: type,
        doc_id: id,
        doc_url: currentUrl,
      },
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, fetchData).then((res) => {
    return res;
  });
}

export function useNewsletterPost(
  meta: NewsletterData['meta'],
  pageProps: any,
) {
  return useCallback(
    async (userEmail: string) => {
      let title;
      let type;
      let id;
      const currentUrl = document.location.href || '';
      if (pageProps?.data) {
        title = pageProps?.data?.attributes?.title ?? pageProps?.data?.title;
        type =
          pageProps?.data?.type ??
          contentTypeToDocumentType[pageProps?.data?.contentType];
        id = pageProps?.data?.id;
      } else {
        const { documentId, documentTitle, documentType } =
          utils.getDocumentDataset();
        title = documentTitle;
        type = documentType;
        id = documentId?.split('-')[0];
      }
      const user = parseUserClient();
      const userSegment = user?.segment as UserSegment;
      if (meta.newsletter_id) {
        const newsletterPreferencesEndpoint = `${getEndpoint(
          'v8',
        )}/users/newsletter_preferences`;
        try {
          const method = 'POST';
          const exactTargetId = parseInt(meta.newsletter_id);
          const emailCaptureType = 'newsletterBelt';
          const response = await updateNewsletterPreferences(
            newsletterPreferencesEndpoint,
            exactTargetId,
            userEmail,
            method,
            userSegment,
            currentUrl,
            emailCaptureType,
            id,
            title,
            type,
          );
          if (response.ok) {
            const { global_resubscribe } = await response.json();
            if (global_resubscribe) {
              AnalyticsService.track(events.GLOBAL_RESUBSCRIBE_EMAIL, {
                location: 'newsletter preference center',
                email_capture_type: emailCaptureType,
                site_key: 'atk',
                email: userEmail,
              });
            }
          }
        } catch (error) {
          honeybadger.notify(`newsletter preferences: ${error}`);
        }
      } else {
        const data = meta;
        data.email = userEmail;
        data.sfmc_params = {
          email_capture_type: 'newsletter',
          current_url: document.location.href || '',
          user_segment: userSegment,
        };
        try {
          const url = atk.getUrl(
            'v8',
            '/users/newsletter_preferences/journey_event',
          );
          const response = await atk.post(url, data, null);
          if (response) {
            const { global_resubscribe } = await response;
            if (global_resubscribe) {
              AnalyticsService.track(events.GLOBAL_RESUBSCRIBE_EMAIL, {
                location: 'newsletter preference center',
                email_capture_type: 'newsletter',
                site_key: 'atk',
                email: userEmail,
              });
            }
          }
        } catch (error) {
          honeybadger.notify(`newsletter preferences: ${error}`);
        }
      }
    },
    [meta, pageProps],
  );
}

export default function useNewsletterState(category: Category) {
  if (!(category in newsletters)) {
    // eslint-disable-next-line no-console
    console.error('category not found in newsletters', category);
    category = 'Well-Equipped Cook';
  }

  const { pageProps } = useAppContext();

  const commonProps = useMemo(() => {
    const pageType = pageProps?.data?.type ?? '';
    let miseHomepageType;
    if (category === 'Homepage Notes from the Test Kitchen') {
      miseHomepageType = 'Notes from the Test Kitchen';
    }

    if (pageType === 'home_page' && miseHomepageType) {
      return {
        location: 'Homepage',
        type: miseHomepageType,
      };
    }

    if (pageType === 'home_page') {
      return {
        location: 'Homepage',
      };
    }

    if (pageType === 'LandingPage::ReviewsLandingPage') {
      return {
        location: '/reviews',
      };
    }

    if (pageType === 'LandingPage::RecipesLandingPage') {
      return {
        location: '/recipes',
      };
    }

    if (
      pageType === 'article' ||
      pageType === 'barista_article' ||
      pageType === 'guide'
    ) {
      const documentTitle = pageProps?.data?.attributes?.title ?? '';

      return {
        location: `${pageType}_detail`,
        document_title: documentTitle,
        document_type: pageType,
      };
    }

    if (pageType === 'LandingPage::SeasonalLandingPage') {
      let loc;
      if (pageProps?.data?.slug === 'thanksgiving') {
        loc = 'Thanksgiving guide';
      }
      if (pageProps?.data?.slug === 'tng') {
        loc = 'next generation landing page';
      }
      return {
        location: loc,
        type: 'newsletterBelt',
      };
    }

    if (pageProps?.data?.contentType === 'EquipmentReview') {
      return {
        location: 'equipment_reviews',
      };
    }

    if (pageProps?.data?.contentType === 'TasteTest') {
      return {
        location: 'taste_tests',
      };
    }

    return {
      location: '',
    };
  }, [category, pageProps]);

  const { view, meta } = newsletters[category];
  const newsletterPost = useNewsletterPost(meta, pageProps);

  const ref = useRef<HTMLDivElement>(null);

  useTrackDisplayAdPresentedEffect(ref, {
    ...commonProps,
    type: 'newsletterBelt',
  } as DisplayAdArgument);

  const trackAccepted = useTrackDisplayAdAcceptedCallback({
    ...commonProps,
    type: 'newsletterBelt',
  } as DisplayAdArgument);

  const trackEmailCapture = useTrackEmailCaptureCallback({
    location: commonProps.location,
    type: 'newsletterBelt',
  } as EmailCaptureProperties);

  const [success, setSuccess] = useState(false);

  const onSubmit = useCallback(
    (email: string) => {
      newsletterPost(email);
      trackAccepted();
      trackEmailCapture(email);
      analytics.track('NEWSLETTER_PREFERENCES', {
        ...commonProps,
        email,
        status: 'optin',
        type: category,
      });
      setSuccess(true);
    },
    [category, commonProps, newsletterPost, trackAccepted, trackEmailCapture],
  );

  const miseHomepageOnSubmit = useCallback(
    (email: string) => {
      newsletterPost(email);
      trackEmailCapture(email);
      analytics.track('NEWSLETTER_PREFERENCES', {
        ...commonProps,
        email,
        status: 'optin',
        type: commonProps.type ?? category,
      });
      setSuccess(true);
    },
    [category, commonProps, newsletterPost, trackEmailCapture],
  );

  const onRender = useCallback(() => {
    analytics.track('DISPLAY_AD', {
      ...commonProps,
      status: 'Presented',
      type: commonProps.type ?? category,
    });
    analytics.track('NEWSLETTER_SIGNUP_PRESENTED', {
      ...commonProps,
      type: commonProps.type ?? category,
    });
  }, [category, commonProps]);

  return {
    view,
    success,
    onRender,
    onSubmit,
    miseHomepageOnSubmit,
    ref,
  };
}
